
   import { useGeolocation } from "vue-composable";
   import Camera from "simple-vue-camera";
   import {computed, ref} from "vue";
   import axios from "axios";
   import { useStore } from 'vuex'
   import { useRouter } from 'vue-router'
   import {useToast} from "primevue/usetoast";
   import CheckinResponse from "@/models/checkinResponse";

   export default {
  setup(){
    const camera = ref<InstanceType<typeof Camera>>();
    const lat = ref(0);
    const long = ref(0);
    const toast = useToast();
    const checkin = ref(false);
    const loadingBar = ref(false);
    const checkinResponse = ref({} as CheckinResponse);
    const cameraPermission = ref(true);
    const router = useRouter();
    const store = useStore();
    const snapshot = async () => {
      const blob = await camera.value?.snapshot(
          { width: 135, height: 240 },
          "image/jpeg",
          0.5
      );
      //
      // console.log("################## location: " + JSON.stringify(kordinat.coords));
      // console.log("################## location: " + JSON.stringify(kordinat.coords.value?.longitude));
      // console.log("################## location: " + JSON.stringify(kordinat.coords.value?.latitude));
      // console.log("################## blob: " + JSON.stringify(blob));
      if(kordinat.coords.value != null)
      {
        lat.value = kordinat.coords.value?.latitude;
        long.value = kordinat.coords.value?.longitude;
      }
      // To show the screenshot with an image tag, create a url
      const url = URL.createObjectURL(blob);
      // open(url);
      var files = new FormData();
      if(blob != null) {
        const blobFile = new File([blob], 'your_file_name.jpeg');
        files.append("formFiles", blobFile);
        axios.defaults.headers.common = {'Authorization': `Bearer ${store.state.token}`}
        if(kordinat.coords.value?.longitude == null || kordinat.coords.value?.longitude == 0){
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:"Vui lòng kiểm tra lại cài đặt định vị, hiện không lấy được dữ liệu định vị",
            life: 4000
          });
        }
        else
        {
          if(!cameraPermission.value){
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:"Vui lòng bật quyền truy cập máy ảnh(camera) và thực hiện lại",
              life: 4000
            });
          }
          else {
            loadingBar.value = true;
            axios.post(`/api/file/UploadCheckIn/${kordinat.coords.value?.longitude}/${kordinat.coords.value?.latitude}`, files,
                {
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                  }
                }).then(response => {
              checkin.value = true;
              checkinResponse.value = response.data;
              loadingBar.value = false;
            })
                .catch(err => {
                  console.log("%%%%$$$$$$$$$$$$$  err.response.status: " + err.response.status);
                  loadingBar.value = false;
                  if(err.response.status == 401)
                  {
                    toast.add({
                      severity: 'error',
                      summary: 'Lỗi',
                      detail: 'Quá hạn đăng nhập, vui lòng đăng nhập lại',
                      life: 4000
                    });
                    store.dispatch('clearToken');
                    store.dispatch('clearPermission');
                    router.push('login');
                  }
                  else {
                    toast.add({
                      severity: 'error',
                      summary: 'Lỗi',
                      detail: err.response.data,
                      life: 4000
                    });
                  }
                })
          }
        }
      }
    }
    const kordinat = useGeolocation({immediate: true});

    const location = computed(() => {
      console.log("################## location: " + JSON.stringify(kordinat.coords));
      console.log("################## location: " + JSON.stringify(kordinat.coords.value?.longitude));
      console.log("################## location: " + JSON.stringify(kordinat.coords.value?.latitude));
    });

    const error = (error: Error) => {
      cameraPermission.value = false;
    };

    return {
      camera,
      snapshot,
      location,
      kordinat,
      lat,
      long,
      checkin,
      checkinResponse,
      loadingBar,
      error
    };

  }
}
